import React, { FC } from 'react';
import styled from 'styled-components';

import BannerOne from '../BannerOne';
import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Headline from '../Headline';
import Button from '../Button';
import CancelButton from '../CancelButton';
import { ImageWithContainer } from '../..';
import List from '../List';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import { DynamicImage } from 'components';
import UpsellReviewSection from '../UpsellReviewSection';
import NumberedList from '../NumberedList';

interface AppSubscriptionProps {}

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f3f3f8;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  width: 100%;
`;
const CancelButtonStyled = styled(CancelButton)`
  margin: 0.75rem 0 3rem 0;
`;

const ListStyled = styled(NumberedList)``;

const TopTextContainer = styled.div`
  background-color: #f3f3f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem 1.75rem;
  @media ${tablet} {
    padding: 0 1rem 1rem;
  }
`;

const HeadlineAlt = styled.p`
  color: #1c1c28;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  max-width: 48rem;
  @media ${tablet} {
    max-width: 30.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const Label = styled.p`
  max-width: 30.5rem;
  color: #28293d;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4375rem;
  padding: 0.75rem 0 2.25rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
    padding: 1rem 1rem 2rem;
  }
`;

const DynamicImageStyled = styled(DynamicImage)`
  max-width: 30.5rem;
  width: 100%;
  height: 100%;
`;

const ImgContainer = styled.div`
  background-color: #f3f3f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;
const DynamicImageStyledBottom = styled(DynamicImage)`
  max-width: 21.4rem;
  width: 100%;
  height: 100%;
`;

const ImgContainerBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`;

const LabelStyled = styled(Label)`
  max-width: 30.5rem;
`;

const HeadlineStyled = styled.p`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.375rem;

  @media ${tablet} {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.8125rem;
  }
`;

const BottomContainer = styled.div`
  background: #f3f3f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadlineTop = styled.p`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  padding: 2rem 1rem 0rem 1rem;
`;

const AppSubscription: FC<AppSubscriptionProps> = ({
  paymentSteps,
  headlineAlt,
  list,
  planSection,
  PlanSectionComponent,
  headline2,
  headline3,
  label,
  planSection2,
  reviewsSection,
  userHasBoughtPaperbackBook,
}) => {
  const { isTablet } = useQuery();
  return (
    <>
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <TopTextContainer>
        <HeadlineAlt>{headlineAlt}</HeadlineAlt>
      </TopTextContainer>
      <ImgContainer>
        <DynamicImageStyled src="upsell/app.png" alt="Nordastro app" />
      </ImgContainer>
      <PlanSectionComponent {...planSection} />
      <ListStyled {...list} />
      <BottomContainer>
        <HeadlineTop dangerouslySetInnerHTML={{ __html: headline2 }} />
        <HeadlineStyled>{headline3}</HeadlineStyled>
        <Label dangerouslySetInnerHTML={{ __html: label }} />
        <ImgContainerBottom>
          <DynamicImageStyledBottom src="upsell/app.png" alt="Nordastro app" />
        </ImgContainerBottom>
        <PlanSectionComponent {...planSection2} />
      </BottomContainer>
      <UpsellReviewSection {...reviewsSection} />
    </>
  );
};

export default AppSubscription;
