import React, { FC } from 'react';
import styled from 'styled-components';
import CardContainer from './CardContainer';
import TickIcon from 'assets/icons/tick-circle.svg';

import { Text } from 'components';
import { smMobile, tablet } from 'styles/breakpoints';
import { ImageWithContainer } from '..';

interface ListProps {
  title: string;
  list: string[];
  padding?: string;
  cardBgColor?: string;
  maxWidth?: string;
  moreText?: string;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  /* background: #fff; */
  padding: 4rem 0rem;
  @media ${tablet} {
    padding: 0;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 71.25rem;
  background-color: rgba(255, 209, 40, 0.1);
`;

const Title = styled(Text)`
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Afacad", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 105%;
  margin-bottom: 2rem;

  @media ${tablet} {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 36.7rem;
  margin-bottom: 0.75rem;
`;

const Item = styled(Text)`
  color: #020124;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-left: 1rem;

  @media ${tablet} {
    font-weight: 600;
    font-size: 0.75rem;
  }
`;

const CardContainerStyled = styled(CardContainer)`
  border-radius: 1rem;
  padding: 3rem;
  margin: 0;
  background-color: transparent;

  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Circle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 99999rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.125rem;
  background: #498d27;
`;

const Number = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Afacad", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

const MoreText = styled(Text)`
  text-align: center;
  font-family: "Afacad", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.018rem;
  color: #498d27;

  @media ${tablet} {
    font-size: 1rem;
    letter-spacing: -0.016rem;
  }
`;

const ListItem = ({ item, number }: { item: string; number: number }) => (
  <ListItemContainer>
    <IconContainer>
      <Circle>
        <Number>{number}</Number>
      </Circle>
    </IconContainer>
    <Item dangerouslySetInnerHTML={{ __html: item }} />
  </ListItemContainer>
);

const NumberedList: FC<ListProps> = ({
  title,
  list,
  padding = '0',
  cardBgColor,
  maxWidth,
  moreText = 'and more',
  ...props
}) => {
  const renderListItem = (item: string, index: number) => (
    <ListItem item={item} key={index} number={index + 1} />
  );
  return (
    <Container {...props}>
      <ContentContainer>
        <CardContainerStyled hideCardBorder={true}>
          <Title>{title}</Title>
          {list?.map(renderListItem)}
          <MoreText>{moreText}</MoreText>
        </CardContainerStyled>
      </ContentContainer>
    </Container>
  );
};

export default NumberedList;
